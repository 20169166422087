/* libs/legacy/gwg/src/lib/shared/identification-verification/identification-verification.component.scss */
h2 {
  text-transform: uppercase;
}
h2,
h3 {
  font-weight: normal;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #5e6f79;
}
h2 {
  margin-bottom: 10px;
  margin-top: 30px;
}
h1,
h3 {
  margin-bottom: 30px;
}
.align-center {
  display: flex;
  justify-content: center;
}
hr {
  border-top: 1px solid #eee;
}
button {
  margin-bottom: 15px;
}
img {
  max-width: 800px;
  max-height: 500px;
  margin-bottom: 10px;
}
h4 {
  margin: 10px 0;
}
/*# sourceMappingURL=identification-verification.component.css.map */

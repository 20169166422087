<div>
  <img [src]="frontsidePicture"
       alt=""
       *ngIf="!isFrontsidePdf">
  <pdf-viewer [original-size]="false"
              [render-text]="false"
              class="image-preview"
              [src]="frontsidePicture"
              style="display: block;"
              *ngIf="isFrontsidePdf">
  </pdf-viewer>
  <img [src]="backsidePicture"
       alt=""
       *ngIf="!isBacksidePdf">
  <pdf-viewer [original-size]="false"
              [render-text]="false"
              class="image-preview"
              [src]="backsidePicture"
              style="display: block;"
              *ngIf="isBacksidePdf">
  </pdf-viewer>
</div>
// #region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { WhatsNewWidgetViewPresenter } from './WhatsNewWidgetViewPresenter';

// #endregion

/**
 * The `WhatsNewWidgetView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-whats-new-widget-view',
    templateUrl: './WhatsNewWidgetView.html',
    styleUrls: ['./WhatsNewWidgetView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        WhatsNewWidgetViewPresenter,
    ],
    standalone: false,
})
export class WhatsNewWidgetView implements OnInit, OnDestroy {

    // #region Fields

    private readonly _presenter: WhatsNewWidgetViewPresenter;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `WhatsNewWidgetView` class.
     *
     * @public
     */
    public constructor(presenter: WhatsNewWidgetViewPresenter) {
        this._presenter = presenter;
    }

    // #endregion

    // #region Proeprties

    /**
     * Returns the presenter of the `WhatsNewWidgetView`
     *
     * @public
     * @readonly
     */
    public get presenter(): WhatsNewWidgetViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    // #endregion

}

<div class="page-container">
    <h1 class="headline">Impressum</h1>
    <div class="box">
        <p>abcfinance GmbH</p>
        <p>Kamekestraße 2-8</p>
        <p>50672 Köln</p>
        <p>Telefon: (0221) 579 08-0</p>
        <p>Telefax: (0221) 579 08-9126</p>
        <p><a href="">E-Mail: info&#64;abcfinance.de</a></p>
    </div>
    <div class="box">
        <h4>Geschäftsführung:</h4>
        <p>Dr. Hubertus Mersmann (Sprecher)</p>
        <p>Stephan Ninow</p>
        <p>Malte Rüsing</p>
    </div>
    <div class="box">
        <h4>Sitz der Gesellschaft:</h4>
        <p>Köln</p>
        <p>Amtsgericht Köln</p>
        <p>HRB 7873</p>
        <p>USt-IdNr.: DE 811196025</p>
    </div>
    <div class="box">
        <p>Ein Unternehmen der Werhahn-Gruppe</p>
    </div>
    <div class="box">
        <h4>Verwaltungsratsvorsitzender:</h4>
        <p>Alexander Boldyreff</p>
    </div>
    <div class="box">
        <h4>Aufsichtsbehörde:</h4>
        <p>Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin)</p>
        <p>Graurheindorfer Straße 108</p>
        <p>53117 Bonn</p>
    </div>
    <div class="box">
        <h4>Alternative Streitbeilegung:</h4>
        <p>
            Nach § 36 VSBG-E ist die abcfinance GmbH verpflichtet, Verbraucher darüber zu informieren, ob wir verpflichtet
            oder bereit sind, den Versuch zu unternehmen, mögliche Streitigkeiten auf Grund oder im Zusammenhang mit einem
            Verbrauchervertrag zunächst über eine Streitschlichtungsstelle beizulegen, bevor der Verbraucher gerichtliche
            Hilfe zur Beilegung/Klärung der Streitigkeit in Anspruch nimmt (alternative Streitbeilegung).
        </p>
        <p>
            Zu Gunsten der Beibehaltung unserer bewährten, kundenorientierten Vorgehensweise haben wir uns gegen das Verfahren
            der alternativen Streitbeilegung entschieden. Bei Meinungsverschiedenheiten oder Streitigkeiten zwischen einem
            Kunden und der abcfinance GmbH suchen wir zunächst immer das direkte Gespräch mit unseren Kunden, unabhängig von
            dem Umstand, ob es sich um einen Verbraucher oder um einen Unternehmer handelt. Unser Ziel ist es, auf
            außergerichtlichem Wege gemeinsam mit dem Kunden eine zufriedenstellende Lösung zu entwickeln. Aufgrund des
            direkten Dialogs mit dem Kunden tragen wir unserer Auffassung nach bereits zur Entlastung der Gerichte bei.
        </p>
        <p>
            Daher erklären wir: Die abcfinance GmbH ist zur Teilnahme an Streitbeilegungsverfahren vor Schlichtungsstellen
            weder verpflichtet noch bereit.
        </p>
    </div>
    <div class="box">
        <h4>Rechtlicher Hinweis:</h4>
        <p>
            Die Redaktion hat diese Website mit größter Sorgfalt erstellt. Eine Haftung oder Garantie für die Aktualität,
            Richtigkeit und Vollständigkeit der zur Verfügung gestellten Informationen und Daten ist jedoch ausgeschlossen.
            Dies gilt auch für alle anderen Websites, auf die mittels eines Hyperlinks verwiesen wird. abcfinance GmbH ist für
            den Inhalt solcher Websites, die mittels einer solchen Verbindung erreicht werden, nicht verantwortlich.
            abcfinance GmbH behält sich vor, ohne Ankündigung, Änderungen oder Ergänzungen der bereitgestellten Informationen
            oder Daten vorzunehmen.
        </p>
        <p>
            Der Inhalt der abcfinance GmbH Website ist urheberrechtlich geschützt. Die Vervielfältigung von Informationen oder
            Daten, insbesondere die Verwendung von Texten, Textteilen oder Bildmaterial bedarf der vorherigen Zustimmung der
            abcfinance GmbH. Der Inhalt dieser Website, insbesondere alle Texte, Bilder, Grafiken und Logos sowie auch ihre
            Arrangements, unterliegen dem Urheberrecht und anderen Gesetzen zum Schutz geistigen Eigentums. abcfinance GmbH
            gewährt jedoch den Nutzern das Recht, die auf dieser Website bereitgestellten Texte, Bilder und Grafiken zu
            publizistischen Zwecken im Rahmen des geltenden Presse- und Urheberrechts ganz oder ausschnittsweise zu verwenden,
            zu speichern und zu vervielfältigen, wobei abcfinance GmbH als Quelle/Urheber genannt und ein Belegexemplar an den
            Bereich Public Relations der abcfinance GmbH (info&#64;abcfinance.de) zur Verfügung gestellt werden muss.
        </p>
        <p>
            Die Verwendung, Speicherung sowie Vervielfältigung der auf der Website verwendeten Logos und deren Arrangements
            bedürfen dagegen der vorherigen Zustimmung der abcfinance GmbH. Die auf der abcfinance GmbH-Website
            bereitgestellten Texte, Bilder, Grafiken, Logos etc. dürfen aber weder verändert, noch für Handelszwecke kopiert
            und auf anderen Websites ohne vorherige Absprache mit der abcfinance GmbH verwendet werden.
        </p>
        <p>
            Die Web-Seite der abcfinance GmbH gewährt auch keinerlei Lizenzrechte an dem geistigen Eigentum der abcfinance
            GmbH und ihrer Beteiligungsgesellschaften. Alle auf der Website genannten Marken und Logos sind gesetzlich
            geschützte Warenzeichen. Sämtliche Informationen oder Daten und Ihre Nutzung unterliegen ausschließlich deutschem
            Recht. Erfüllungsort und ausschließlicher Gerichtsstand ist Köln.
            abcfinance GmbH haftet darüber hinaus weder für direkte noch indirekte Schäden, einschließlich entgangenen
            Gewinns, die durch die Nutzung der auf dieser Website zu findenden Informationen oder Daten entstehen.
            Ausgeschlossen sind auch Rechte und Pflichten zwischen abcfinance GmbH und dem Nutzer dieser Website oder Dritten.
        </p>
    </div>
</div>

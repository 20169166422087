/* libs/legacy/gwg/src/lib/shared/flow-decision/flow-decision.component.scss */
h2 {
  text-transform: uppercase;
}
h2,
h3 {
  font-weight: normal;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #5e6f79;
}
h2 {
  margin-bottom: 10px;
  margin-top: 30px;
}
h1,
h3 {
  margin-bottom: 30px;
}
.align-center {
  display: flex;
  justify-content: center;
}
:host {
  display: block;
  width: 100%;
  height: 100%;
}
:host p {
  font-size: 17px;
  color: #5e6f79;
}
:host .container-buttons {
  margin-top: 16px;
}
:host mat-icon {
  font-size: 80px !important;
  height: 80px;
  width: 80px;
}
:host mat-card-content {
  padding: 0 24px 16px;
}
/*# sourceMappingURL=flow-decision.component.css.map */

<!--#region Templates -->

<ng-template #newsItemTemplate
             let-item>
    <div [id]="'news_item_' + item.id"
         class="item">
        <div fxLayout="row"
             fxLayoutGap="8px">
            <div *ngIf="item.unread"
                 fxLayoutAlign="start start">
                <span class="dot"></span>
            </div>
            <div fxLayout="column"
                 fxLayoutGap="8px">
                <h6 class="published-at">{{item.publishedAt | date: 'longDate'}}</h6>
                <h4 class="header">{{item.title}}</h4>
                <mat-chip-listbox *ngFor="let tag of item.tags">
                    <mat-chip class="small"
                              [color]="tag | tagcolor">{{tag}}</mat-chip>
                </mat-chip-listbox>
                <span class="content"
                      [innerHTML]="item.bodyText | safe: 'html'"></span>
                <div *ngIf="item.imageVideo">
                    <img *ngIf="item.imageVideo.url && item.imageVideo.type !== 'video/mp4'"
                         [src]="item.imageVideo.url"
                         [alt]="item.imageVideo.title"
                         loading="lazy"
                         class="media">
                    <iframe *ngIf="item.imageVideo.embed"
                            width="90%"
                            frameborder="0"
                            allowfullscreen
                            [src]="item.imageVideo | safe:'resource'"></iframe>
                    <video *ngIf="item.imageVideo.type === 'video/mp4'"
                           controls
                           loading="lazy"
                           class="media">
                        <source [src]="item.imageVideo.url"
                                [type]="item.imageVideo.type">
                    </video>
                </div>
                <div *ngIf="item.image">
                    <img *ngIf="item.image.url && item.image.type !== 'video/mp4'"
                         [src]="item.image.url"
                         [alt]="item.image.title"
                         loading="lazy"
                         class="media">
                    <iframe *ngIf="item.image.embed"
                            width="90%"
                            frameborder="0"
                            allowfullscreen
                            [src]="item.image | safe:'resource'"></iframe>
                    <video *ngIf="item.image.type === 'video/mp4'"
                           controls
                           loading="lazy"
                           class="media">
                        <source [src]="item.image.url"
                                [type]="item.image.type">
                    </video>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #faqItemTemplate
             let-item>
    <div class="item"
         fxLayout="column"
         fxLayoutGap="8px">
        <h6 class="published-at">{{item.publishedAt | date: 'longDate'}}</h6>
        <h4 class="header">{{item.title}}</h4>
        <span class="content"
              [innerHTML]="item.bodyText | safe: 'html'"></span>
        <div *ngIf="item.imageVideo">
            <img *ngIf="item.imageVideo.url && item.imageVideo.type !== 'video/mp4'"
                 [src]="item.imageVideo.url"
                 [alt]="item.imageVideo.title"
                 loading="lazy"
                 class="media">
            <iframe *ngIf="item.imageVideo.embed"
                    width="90%"
                    [src]="item.imageVideo | safe:'resource'"
                    frameborder="0"
                    allowfullscreen></iframe>
            <video class="media"
                   controls
                   loading="lazy"
                   *ngIf="item.imageVideo.type === 'video/mp4'">
                <source [src]="item.imageVideo.url"
                        [type]="item.imageVideo.type">
            </video>
        </div>
    </div>
</ng-template>

<!-- #endregion -->

<l7-dialog-header [title]="('news.' + (presenter.data | async ).kind + '.title') | translate"
                  [closeable]="true"
                  (closed)="presenter.onClose(true)"></l7-dialog-header>
<mat-dialog-content fxLayout="column"
                    fxLayoutGap="32px">
    <ng-container *ngFor="let item of (presenter.items | async); let last = last;">
        <ng-container *ngTemplateOutlet="(presenter.data | async).kind === 'news' ? newsItemTemplate : faqItemTemplate; context: { $implicit: item } "></ng-container>
        <mat-divider *ngIf="!last"></mat-divider>
    </ng-container>
</mat-dialog-content>

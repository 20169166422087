<div class="gwg-dependency-bar">
    <div class="icons-container">
        <div class="icon-container"
             [ngClass]="creditState?.credit_rating">
            <mat-icon *ngIf="creditState?.credit_rating !== 'APPROVED_WITH_CONDITIONS' && creditState?.credit_rating !== 'APPROVED_WITH_CONDITIONS_FULFILLED' && creditState?.credit_rating !== 'POSITIVE'  && creditState?.credit_rating !== 'NEGATIVE'"
                [inline]="true"
                matTooltip="{{credit_state_tooltip}}"
                [fontSet]="'l7'"
                class="icon-lessee"
                [fontIcon]="'Shield'"
                matTooltipClass="tooltip-info"
                [ngClass]="creditState?.credit_rating"></mat-icon>
            <mat-icon *ngIf="creditState?.credit_rating === 'NEGATIVE'"
                      [inline]="true"
                      matTooltip="{{credit_state_tooltip}}"
                      [fontSet]="'l7'"
                      class="icon-lessee"
                      [fontIcon]="'Shield_Cross'"
                      matTooltipClass="tooltip-info"
                      [ngClass]="creditState?.credit_rating"></mat-icon>
            <mat-icon *ngIf="creditState?.credit_rating === 'POSITIVE'"
                      [inline]="true"
                      matTooltip="{{credit_state_tooltip}}"
                      [fontSet]="'l7'"
                      class="icon-lessee"
                      [fontIcon]="'Shield_Check'"
                      matTooltipClass="tooltip-info"
                      [ngClass]="creditState?.credit_rating"></mat-icon>
            <div *ngIf="creditState?.credit_rating === 'APPROVED_WITH_CONDITIONS' || creditState?.credit_rating === 'APPROVED_WITH_CONDITIONS_FULFILLED'"
                 [matTooltip]="credit_state_tooltip"
                 class="icon-lessee btn-with-conditions"
                 matTooltipClass="tooltip-info"
                 matBadge="A"
                 matBadgeOverlap="true"
                 matBadgeSize="small">
                <mat-icon [fontSet]="'l7'" [fontIcon]="'Shield_Check'" [ngClass]="creditState?.credit_rating"></mat-icon>
            </div>
        </div>
    </div>
</div>

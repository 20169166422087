import { Component, Input } from '@angular/core';

@Component({
    selector: 'l7-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: false,
})
export class FooterComponent {

    @Input() set show(value: boolean) {
        this._show = value;
    }

    private _show: boolean = false;

    constructor() {
    }

    get show_footer(): boolean {
        return this._show;
    }

}

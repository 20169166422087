<div fxFlex>
    <form [formGroup]="internalForm"
          l7ScrollToError
          ngxsForm="{{ngxsFormName}}"
          id="form_bank-account-checker">
        <div fxFlex
             fxLayout="column">
            <ng-container *ngIf="showOnlyOwnerAndIban || isOwnerEditable; else oldStyle">
                <div fxLayout="row"
                     fxLayout.lt-sm="column"
                     fxLayoutGap.lt-sm="0px"
                     fxLayoutGap="16px">
                    <mat-form-field appearance="outline"
                                    [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ' - 16px)'">
                        <mat-label>Kontoinhaber</mat-label>
                        <input l7InputRef
                               matInput
                               l7MarkAsTouched
                               placeholder="Kontoinhaber"
                               formControlName="bank_account_name"
                               [value]="accountOwner"
                               id="input_bank-account-checker-account-owner">
                    </mat-form-field>
                    <mat-form-field appearance="outline"
                                    [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ' - 16px)'">
                        <mat-label>IBAN</mat-label>
                        <input l7InputRef
                               matInput
                               l7MarkAsTouched
                               (keyup)="transformToUpperCase($event)"
                               type="text"
                               placeholder="IBAN"
                               mask="AAAA AAAA AAAA AAAA AAAA AA"
                               [validation]="false"
                               formControlName="iban"
                               l7IbanValidator
                               id="input_bank-account-checker-input_iban">
                        <div *ngIf="(internalForm.get('iban').statusChanges | async) === 'PENDING'"
                             matSuffix>
                            <mat-progress-spinner [diameter]="16"
                                                  [strokeWidth]="2"
                                                  [color]="'primary'"
                                                  [mode]="'indeterminate'"></mat-progress-spinner>
                        </div>
                        <mat-error *ngIf="internalForm.get('iban')?.errors?.['iban']">
                            <p>{{ 'iban.errors.invalid_iban_checksum' | translate }}</p>
                        </mat-error>
                    </mat-form-field>
                </div>
            </ng-container>

            <ng-template #oldStyle>
                <div fxLayout="row"
                     fxLayout.lt-sm="column"
                     fxLayoutGap.lt-sm="0px"
                     fxLayoutGap="16px">
                    <mat-form-field appearance="outline"
                                    [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ' - 16px)'"
                                    [ngStyle]="{'display': bank_owner_display}">
                        <mat-label>Kontoinhaber</mat-label>
                        <input l7InputRef
                               matInput
                               l7MarkAsTouched
                               placeholder="Kontoinhaber"
                               formControlName="bank_account_name"
                               [value]="accountOwner"
                               id="input_bank-account-checker-account-owner">
                    </mat-form-field>
                    <mat-form-field appearance="outline"
                                    [fxFlex]="'0 1 calc(' + 100 / 2 + '%' + ' - 16px)'">
                        <mat-label>IBAN</mat-label>
                        <input l7InputRef
                               matInput
                               l7MarkAsTouched
                               (keyup)="transformToUpperCase($event)"
                               type="text"
                               placeholder="IBAN"
                               mask="AAAA AAAA AAAA AAAA AAAA AA"
                               [validation]="false"
                               formControlName="iban"
                               l7IbanValidator
                               id="input_bank-account-checker-input_iban">
                            <div *ngIf="(internalForm.get('iban').statusChanges | async) === 'PENDING'"
                             matSuffix>
                            <mat-progress-spinner [diameter]="16"
                                                  [strokeWidth]="2"
                                                  [color]="'primary'"
                                                  [mode]="'indeterminate'"></mat-progress-spinner>
                        </div>
                        <mat-error *ngIf="internalForm.get('iban')?.errors?.['iban']">
                            <p>{{ 'iban.errors.invalid_iban_checksum' | translate }}</p>
                        </mat-error>
                        <mat-error *ngIf="internalForm.controls.iban.getError('required')">
                            <p>{{ 'iban.errors.required' | translate }}</p>
                        </mat-error>
                    </mat-form-field>
                </div>
            </ng-template>

            <div fxLayout="row"
                 fxLayout.lt-sm="column"
                 fxLayoutGap="16px"
                 *ngIf="showOnlyOwnerAndIban === false">
                <mat-form-field appearance="outline"
                                fxFlex="50">
                    <mat-label>Name der Bank</mat-label>
                    <input l7InputRef
                           matInput
                           placeholder="Name der Bank"
                           formControlName="bank_name"
                           id="input_bank-account-checker_bank-name">
                </mat-form-field>
                <mat-form-field appearance="outline"
                                fxFlex="50">
                    <mat-label>BIC</mat-label>
                    <input l7InputRef
                           matInput
                           placeholder="BIC"
                           formControlName="bic"
                           id="input_bic">
                </mat-form-field>
            </div>
        </div>
    </form>
</div>

/* libs/legacy/gwg/src/lib/shared/identification-upload-file/identification-upload-file.component.css */
h3 {
  font-weight: normal;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #5e6f79;
}
h3 {
  margin-top: 20px;
  margin-bottom: 30px;
}
.uploadfilecontainer {
  width: 602px;
  height: 376px;
  margin: 20px auto;
  border: 1px dashed #1b7e9a;
  border-radius: 2px;
}
.dragndrop {
  margin: 61px 231px 55px 231px;
  width: 140px;
  height: 140px;
  object-fit: contain;
  z-index: 9999;
}
.label-upload {
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  color: #83929b;
}
.link-upload {
  margin-top: 10px;
  display: block;
  font-size: 10px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: #1b7e9a;
  width: 97%;
}
.uploadfilecontainer:hover {
  cursor: pointer;
  background-color: #9ecbec !important;
  opacity: 0.8;
}
.container-preview {
  position: relative;
  width: 262px;
  height: 372px;
  border: solid 1px #1b7e9a;
  background-color: #ecf4f7;
  margin: 50px auto;
}
.container-icon {
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  right: -24px;
  top: -24px;
  border-radius: 4px;
  background-color: #4296b1;
  color: #ffffff;
}
.image-preview {
  position: absolute;
  width: 28vw;
  height: 100%;
  object-fit: contain;
}
.line {
  margin-top: 50px;
  margin-bottom: 50px;
  border-top: 1px solid #ffffff;
  width: 90%;
}
.align-center {
  display: flex;
  justify-content: center;
}
.error-message {
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.76;
  letter-spacing: normal;
  text-align: center;
  color: #a0161e;
}
@media (min-width: 576px) {
  .image-preview {
    width: 14vw;
  }
}
@media (min-width: 992px) {
  .image-preview {
    width: 10vw;
  }
}
/*# sourceMappingURL=identification-upload-file.component.css.map */

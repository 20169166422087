import { CreditCheckService, IContactDto } from '@abcfinlab/api/global';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { of } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { SigningLinkDTO } from '../../../../../libs/legacy/gwg/src/lib/models/SigningLinkDTO.interface';
import {
    FetchQuoteByUUID,
    FetchQuotesListByLesseeUUID,
    FetchSigningLinkByUUID,
    GetCreditRating,
    ResetSelectedQuoteID,
    SetCreditRating, SetLesseeForQuote,
    SetNavInfoForQuotes,
    SetQuote,
    SetQuoteID,
    SetVendor,
    UpdateLessee,
    UpdateQuoteBankAccount,
    UpdateVendor,
} from '../actions/Quote.actions';
import { BankAccountDTO } from '../models/BankAccountDTO.interface';
import { SlbCodeFormDTO } from '../models/CalculationFormDTO';
import { ContactDTO } from '../models/ContactDTO.interface';
import { CreditCheckDTO } from '../models/CreditCheckDTO.interface';
import { InsuranceType } from '../models/enums/InsuranceType.enum';
import { LeasingQuoteDTO } from '../models/LeasingQuoteDTO.interface';
import { LeasingQuoteOverviewDTO } from '../models/LeasingQuoteOverviewDTO.interface';
import { VendorDTO } from '../models/VendorDTO.interface';
import { LesseeRepositoryService } from '../private/repository/lessee-repository.service';
import { QuoteRepositoryService } from '../private/repository/quote-repository.service';
import { DialogService } from '../private/services/dialog/dialog.service';
import {
    GenericDialogComponent,
    GenericDialogData,
} from '../shared/modals/generic-dialog/generic-dialog.component';

const defaultsBankAccountForm: BankAccountDTO = {
    bank_account_name: null,
    bank_account_type: 'DIRECT_DEBIT',
    bank_name: null,
    bic: null,
    iban: null,
};

export class QuoteStateModel {

    selectedQuoteID: string;
    selectedQuote: LeasingQuoteDTO;
    quotesByLessee: LeasingQuoteOverviewDTO;
    vendor: VendorDTO;
    lessee: ContactDTO;
    link: SigningLinkDTO;
    bankAccountForm: {
        model: BankAccountDTO;
        dirty: false;
        status: 'INVALID';
        errors: object;
    };

}

@State<QuoteStateModel>({
    name: 'quote',
    defaults: {
        selectedQuoteID: null,
        quotesByLessee: {} as LeasingQuoteOverviewDTO,
        selectedQuote: {} as LeasingQuoteDTO,
        vendor: {} as VendorDTO,
        lessee: {} as ContactDTO,
        link: {} as SigningLinkDTO,
        bankAccountForm: {
            model: defaultsBankAccountForm,
            dirty: false,
            status: 'INVALID',
            errors: {},
        },
    },
})
@UntilDestroy()
@Injectable()
export class QuoteState {

    constructor(
        private readonly _creditCheckService: CreditCheckService,
        private readonly _store: Store,
        private readonly _quoteRepositoryService: QuoteRepositoryService,
        private readonly _lesseeRepositoryService: LesseeRepositoryService,
        private readonly _dialogService: DialogService,
        private readonly ngZone: NgZone,
    ) {
    }

    /**
* Get the list of quotes from state.
* @final
* @param state   QuoteStateModel
*/
    @Selector()
    static getQuotesListForLessee(state: QuoteStateModel): LeasingQuoteOverviewDTO {
        return state.quotesByLessee;
    }

    /**
* Get the UUID of the selected quote.
* @param   state     State
* @final
*/
    @Selector()
    static selectedQuoteID(state: QuoteStateModel): string {
        return state.selectedQuoteID;
    }

    @Selector()
    static getQuote(state: QuoteStateModel): LeasingQuoteDTO {
        return state.selectedQuote;
    }

    @Selector()
    static getSaleAndLeaseBack(state: QuoteStateModel): SlbCodeFormDTO {
        return state.selectedQuote.sale_and_lease_back;
    }

    @Selector()
    static getQuoteCreditRating(state: QuoteStateModel): CreditCheckDTO {
        return state.selectedQuote.credit_rating;
    }

    @Selector()
    static getLink(state: QuoteStateModel): SigningLinkDTO {
        return state.link;
    }

    @Selector()
    static getVendor(state: QuoteStateModel): VendorDTO {
        return state.vendor;
    }

    @Selector()
    static getBankAccount(state: QuoteStateModel): BankAccountDTO {
        return state.bankAccountForm.model;
    }

    /**
* Reset the the quote UUID selection.
* @final
* @param   ctx     Store context
*/
    @Action(ResetSelectedQuoteID)
    resetSelectedQuoteID(ctx: StateContext<QuoteStateModel>) {
        ctx.patchState({
            selectedQuoteID: null,
        });
    }

    @Action(SetQuoteID)
    setSelectedQuoteID({ getState, patchState, dispatch }: StateContext<QuoteStateModel>, { payload }: SetQuoteID) {
        patchState({
            selectedQuoteID: payload,
        });
    }

    /**
* Get a quote on a given UUID.
* @param patchState      Context
* @param getState      Get Context
* @param UUID            The quotes UUID
* @final
*/
    @Action(FetchQuoteByUUID)
    fetchQuoteByUUID({ getState, patchState }: StateContext<QuoteStateModel>, { UUID, contractType }: FetchQuoteByUUID) {
        const ctx = getState();
        return this._quoteRepositoryService.getQuoteByUUID(UUID, contractType).pipe(
            tap((quote) => {
                const bankAccount = quote.bank_account
                    ? {
                            ...quote.bank_account,
                            ...{
                                bank_account_name: ctx.lessee.name,
                                bank_account_type: 'DIRECT_DEBIT',
                            },
                        }
                    : {
                            bank_account_name: ctx.lessee.name,
                            bank_name: null,
                            bic: null,
                            iban: null,
                            bank_account_type: 'DIRECT_DEBIT',
                        };
                patchState({
                    selectedQuote: {
                        ...ctx.selectedQuote,
                        ...quote,
                        quote_calculation: {
                            ...quote.quote_calculation,
                            insurance_fee: quote.quote_calculation.insurance_fee,
                            insurance_type: quote.quote_calculation.insurance_type ? quote.quote_calculation.insurance_type : InsuranceType.NO,
                        },
                        sale_and_lease_back: quote.sale_and_lease_back ? quote.sale_and_lease_back : null,
                        bank_account: bankAccount,
                    },
                    vendor: quote.objects[0].vendor,
                    bankAccountForm: {
                        ...ctx.bankAccountForm,
                        model: bankAccount,
                    },
                });
            }),
        );
    }

    /**
* Get a signing link on a given UUID.
* @param patchState      Context
* @param UUID            The quotes UUID
* @final
*/
    @Action(FetchSigningLinkByUUID)
    fetchSigningLinkByUUID({ patchState }: StateContext<QuoteStateModel>, { UUID }: FetchQuoteByUUID) {
        return this._quoteRepositoryService.fetchSigningLink(UUID).pipe(
            tap((link) => {
                patchState({
                    link,
                });
            }),
        );
    }

    /**
* Set the quote object and select the corresponding lessee for the object
*/
    @Action(SetQuote)
    setQuote({ getState, patchState, dispatch }: StateContext<QuoteStateModel>, { payload }: SetQuote) {
        const ctx = getState();
        const lesseeUUID = ctx.selectedQuote.lessee_id;
        return this._lesseeRepositoryService.getLessee(lesseeUUID).pipe(
            tap((lessee: ContactDTO) => {
                patchState({
                    selectedQuote: {
                        ...ctx.selectedQuote,
                        ...payload,
                    },
                    lessee,
                });
            }),
        );
    }

    /**
* Fetch all quotes by a given lessee UUID.
* @param lesseeUUID      The lessee UUID
* @final
*/
    @Action(FetchQuotesListByLesseeUUID)
    fetchQuotesListByLesseeUUID({ patchState, dispatch }: StateContext<QuoteStateModel>, { lesseeUUID }: FetchQuotesListByLesseeUUID) {
        return this._quoteRepositoryService.getQuotesByLesseeUUID(lesseeUUID).pipe(
            tap((quotes) => {
                patchState({
                    quotesByLessee: quotes,
                });
                dispatch(new SetNavInfoForQuotes());
            }),
        );
    }

    @Action(UpdateVendor)
    updateVendor({ getState, patchState, dispatch }: StateContext<any>, { vendor, UUID }: UpdateVendor) {
        const ctx = getState();
        return this._quoteRepositoryService.updateVendorForQuote(vendor, UUID).pipe(
            tap(() => {
                patchState({
                    ...ctx,
                });
            }),
        );
    }

    @Action(UpdateLessee)
    updateLessee({ getState, patchState, dispatch }: StateContext<any>, { lessee, UUID }: UpdateLessee) {
        const ctx = getState();
        return this._lesseeRepositoryService.updateLesseeForQuote(lessee as IContactDto, UUID).pipe(
            tap(() => {
                patchState({
                    ...ctx,
                });
            }),
        );
    }

    /**
* Set the quote vendor
*/
    @Action(SetVendor)
    setVendor({ getState, patchState, dispatch }: StateContext<QuoteStateModel>, { vendor }: SetVendor) {
        const ctx = getState();

        return this._quoteRepositoryService.setVendorForQuoteV2(vendor).pipe(
            untilDestroyed(this),
            tap((lessee: ContactDTO) => {
                patchState({
                    ...ctx,
                    vendor,
                });
            }),
        ).subscribe(() => { }, _error => this.openDialog(_error));
    }

    /**
* Set the credit rating for selected quote
*/
    @Action(SetCreditRating)
    setCreditRating({ getState, patchState }: StateContext<QuoteStateModel>, { creditRating }: SetCreditRating) {
        const ctx = getState();
        patchState({
            ...ctx,
            selectedQuote: {
                ...ctx.selectedQuote,
                credit_rating: creditRating,
            },
        });
    }

    /**
* Set the credit rating for selected quote
*/
    @Action(SetLesseeForQuote)
    setLesseeForQuote({ getState, patchState }: StateContext<QuoteStateModel>, { lessee }: SetLesseeForQuote) {
        const ctx = getState();
        patchState({
            ...ctx,
            lessee,
        });
    }

    /**
* Get the credit rating for quote
*/
    @Action(GetCreditRating)
    getCreditRating(_ctx: StateContext<QuoteStateModel>, { quoteId, lesseeId }: GetCreditRating) {
        return this._creditCheckService.addRequest({ quoteId }).pipe(
            concatMap(() => this._creditCheckService.getCreditCheckDetail({ quoteId })),
            concatMap(creditCheck => this._store.dispatch(new FetchQuotesListByLesseeUUID(lesseeId))),
        );
    }

    /**
* Update the quote on saving
*/
    @Action(UpdateQuoteBankAccount)
    updateQuoteBankAccount({ getState, patchState }: StateContext<QuoteStateModel>, { quote }: UpdateQuoteBankAccount) {
        const ctx = getState();
        return patchState({
            ...ctx,
            selectedQuote: {
                ...ctx.selectedQuote,
                bank_account: quote.bank_account,
            },
        });
    }

    /**
* Get the credit rating for quote
*/
    @Action(SetNavInfoForQuotes)
    fetchNavInfoForQuotes(ctx: StateContext<QuoteStateModel>) {
        ctx.getState().quotesByLessee.quotes?.map((_quote, index) => {
            this._quoteRepositoryService.fetchNavInfo(_quote.quote_number)
                .subscribe((_navInfo) => {
                    ctx.patchState({
                        ...ctx.getState(),
                        quotesByLessee: {
                            quotes: ctx.getState().quotesByLessee.quotes?.map((item) => {
                                if (item.quote_name === _quote.quote_name) {
                                    return { ...item, nav_info: _navInfo };
                                }
                                return item;
                            }, []),
                        },
                    });
                    return of(true);
                });
        });
    }

    private openDialog(_error: HttpErrorResponse) {
        this.ngZone.run(() => {
            if (_error instanceof HttpErrorResponse && _error.status === 409 && (_error.error.error === 'vendor_crefo_score' || _error.error.error === 'vendor_no_crefo_score')) {
                const data: GenericDialogData = {
                    id: 'dialog_contact_vendor_crefo_score',
                    image: 'assets/images/image-failure.svg',
                    title: 'Fehler',
                    body: `error.${_error.error.error}`,
                    negativeText: 'global.close',
                };
                this._dialogService
                    .openDialog(GenericDialogComponent, {}, data);
            }
        });
    }

}

<div class="headline-container" fxLayout="row">
  <div fxFill fxLayout="column">
    <h4 class="headline__prefix">{{prefix}}</h4>
    <h1 class="headline">{{headline | translate}}</h1>
    <div fxLayout="row">
      <h2 class="headline__additional">{{additional | translate}}</h2>
      <ng-content select=".headline-additional-suffix"></ng-content>
    </div>
    <h3 *ngIf="navisionNumber" class="headline__subheadline">{{'global.nav_contact' | translate}}: {{navisionNumber}}</h3>
    <h4 *ngIf="subheadline" class="headline__subheadline">{{subheadline | translate}}</h4>
  </div>
</div>

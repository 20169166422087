// #region Imports

import { DirectusService } from '@abcfinlab/api/global';
import { AuthService } from '@abcfinlab/auth';
import { subscribeOnce } from '@abcfinlab/core';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription, interval, of } from 'rxjs';
import { catchError, startWith, takeWhile } from 'rxjs/operators';
import { NewsKind } from '../Models/NewsKind';
import { WhatsNewView } from '../Views/WhatsNewView';

// #endregion

/**
 * The `NewsManager` class.
 *
 * @public
 */
@Injectable({ providedIn: 'root' })
export class NewsManager {

    // #region Fields

    // magic interval... ask JOHÖ :D
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private static readonly POLL_INTERVAL: number = 144000;

    private readonly _dialog: MatDialog;
    private readonly _directusService: DirectusService;
    private readonly _authService: AuthService;
    private readonly _unreadCountSubject: BehaviorSubject<number>;

    private _pollingSubscription: Subscription | null;
    private _dialogRef: MatDialogRef<WhatsNewView> | null;
    private _isPolling: boolean;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `NewsManager` class.
     *
     * @public
     */
    public constructor(dialog: MatDialog, directusService: DirectusService, authService: AuthService) {
        this._dialog = dialog;
        this._dialogRef = null;
        this._directusService = directusService;
        this._authService = authService;
        this._unreadCountSubject = new BehaviorSubject(0);
        this._isPolling = false;
        this._pollingSubscription = null;
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `unreadCount` property.
     *
     * @public
     * @readonly
     */
    public get unreadCount(): Observable<number> {
        return this._unreadCountSubject.asObservable();
    }

    // #endregion

    // #region Methods

    /**
     * @public
     */
    public show(kind: NewsKind, id?: number): void {
        if (!this._dialogRef) {
            this._dialogRef = this._dialog.open(WhatsNewView, {
                autoFocus: false,
                data: {
                    kind: kind,
                    id: id,
                },
            });
            subscribeOnce(this._dialogRef.afterOpened(), () => this.stopPolling());
            subscribeOnce(this._dialogRef.afterClosed(), () => {
                this.startPolling();
                this._dialogRef = null;
            });
        }
    }

    /**
     * @public
     */
    public startPolling(): void {
        if (this._isPolling) {
            return;
        }

        this._isPolling = true;

        subscribeOnce(this._authService.hasSession, (isLoggedIn) => {
            if (isLoggedIn && (!this._pollingSubscription || this._pollingSubscription?.closed)) {
                this._pollingSubscription = interval(NewsManager.POLL_INTERVAL)
                    .pipe(
                        startWith(0),
                        takeWhile(() => isLoggedIn),
                    ).subscribe(() => {
                        subscribeOnce(this._directusService.forWhomTheBellTolls().pipe(
                            catchError(err => of([])),
                        ), x => this._unreadCountSubject.next((x as any).length));
                    });
            }
        });
    }

    /**
     * @public
     */
    public stopPolling(): void {
        if (!this._isPolling) {
            return;
        }

        this._isPolling = false;

        this._unreadCountSubject.next(0);
        this._pollingSubscription?.unsubscribe();
    }

    // #endregion

}

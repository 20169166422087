<div>
  <h3>Bitte fotografieren Sie die <span class="text-bold">{{text.toUpperCase()}}</span> des Personalausweises des Unterzeichners ab. Der Ausweis sollte dafür die
    vorgegebene Form ausfüllen.</h3>
  <div>
    <div class="align-center">
      <webcam [trigger]="triggerObservable"
              (imageCapture)="handleImage($event)"
              *ngIf="showWebcam"
              [height]="500"
              [width]="800"
              [allowCameraSwitch]="allowCameraSwitch"
              [switchCamera]="nextWebcamObservable"
              [videoOptions]="videoOptions"
              mirrorImage="never"
              [imageQuality]="1"
              (cameraSwitched)="cameraWasSwitched($event)"
              (initError)="handleInitError($event)"
              #webcamComponent></webcam>
    </div>
  </div>
  <div class="align-center">
      <l7-spacer [thickness]="'all'" [multiplier]="24">
          <button mat-flat-button
                  [color]="'accent'"
                  id="button_abort-calculation"
                  (click)="recordSubmitted()">
              Aufnahme
          </button>
      </l7-spacer>
  </div>
</div>

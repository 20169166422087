import * as i0 from '@angular/core';
import { Injectable, isDevMode, makeEnvironmentProviders, ENVIRONMENT_INITIALIZER } from '@angular/core';
import * as i1 from '@ngxs/store';
import { ofActionSuccessful, InitState, UpdateState, getValue, setValue, getActionTypeFromInstance, withNgxsPlugin } from '@ngxs/store';
import { take } from 'rxjs/operators';
import { ɵgetStoreMetadata as _getStoreMetadata } from '@ngxs/store/internals';
function noop() {
  return () => {};
}
class ResetService {
  initialState;
  /** @nocollapse */
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "18.0.4",
    ngImport: i0,
    type: ResetService,
    deps: [],
    target: i0.ɵɵFactoryTarget.Injectable
  });
  /** @nocollapse */
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "18.0.4",
    ngImport: i0,
    type: ResetService
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.0.4",
  ngImport: i0,
  type: ResetService,
  decorators: [{
    type: Injectable
  }]
});
class ResetHandler {
  actions$;
  store;
  resetService;
  constructor(actions$, store, resetService) {
    this.actions$ = actions$;
    this.store = store;
    this.resetService = resetService;
    this.actions$.pipe(ofActionSuccessful(InitState), take(1)).subscribe(() => this.resetService.initialState = this.store.snapshot());
    this.actions$.pipe(ofActionSuccessful(UpdateState)).subscribe(({
      addedStates
    }) => this.resetService.initialState = {
      ...this.resetService.initialState,
      ...addedStates
    });
  }
  /** @nocollapse */
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "18.0.4",
    ngImport: i0,
    type: ResetHandler,
    deps: [{
      token: i1.Actions
    }, {
      token: i1.Store
    }, {
      token: ResetService
    }],
    target: i0.ɵɵFactoryTarget.Injectable
  });
  /** @nocollapse */
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "18.0.4",
    ngImport: i0,
    type: ResetHandler
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.0.4",
  ngImport: i0,
  type: ResetHandler,
  decorators: [{
    type: Injectable
  }],
  ctorParameters: () => [{
    type: i1.Actions
  }, {
    type: i1.Store
  }, {
    type: ResetService
  }]
});

/**
 * Action to clear all state except given state(s)
 */
class StateClear {
  static type = '@@CLEAR_STATE';
  statesToKeep;
  constructor(...statesToKeep) {
    const reducer = createMetaDataListReducer(isDevMode());
    this.statesToKeep = statesToKeep.reduce(reducer, []);
  }
}
/**
 * Action to reset given state(s) to defaults
 */
class StateReset {
  static type = '@@RESET_STATE';
  statesToReset;
  constructor(...statesToReset) {
    const reducer = createMetaDataListReducer(isDevMode());
    this.statesToReset = statesToReset.reduce(reducer, []);
  }
}
/**
 * Action to reset all states expect given state(s) to defaults
 */
class StateResetAll {
  static type = '@@RESET_STATE_ALL';
  statesToKeep;
  constructor(...statesToKeep) {
    const reducer = createMetaDataListReducer(isDevMode());
    this.statesToKeep = statesToKeep.reduce(reducer, []);
  }
}
/**
 * Action to overwrite state(s) with given value(s)
 */
class StateOverwrite {
  static type = '@@OVERWRITE_STATE';
  statesToOverwrite;
  values;
  constructor(...overwriteConfigs) {
    const reducer = createMetaTupleReducer(isDevMode());
    const [states, values] = overwriteConfigs.reduce(reducer, [[], []]);
    this.statesToOverwrite = states;
    this.values = values;
  }
}
function getMetaData(state, devMode) {
  const meta = new Object(_getStoreMetadata(state));
  const isNgxsMeta = meta.name && 'defaults' in meta;
  // Reusability Hack: devMode is number on purpose
  if (!isNgxsMeta && devMode === -2) {
    console.warn(`Reset Plugin Warning: ${meta.name} is not a state class.`);
    return null;
  }
  return meta;
}
function createMetaDataListReducer(devMode) {
  return (acc, state) => {
    // eslint-disable-next-line no-bitwise
    const meta = getMetaData(state, ~devMode);
    return meta ? acc.concat(meta) : acc;
  };
}
function createMetaTupleReducer(devMode) {
  return (acc, [state, value]) => {
    // eslint-disable-next-line no-bitwise
    const meta = getMetaData(state, ~devMode);
    return meta ? [acc[0].concat(meta), acc[1].concat(value)] : acc;
  };
}
class NgxsResetPlugin {
  resetService;
  constructor(resetService) {
    this.resetService = resetService;
  }
  clearStates(state, statesToKeep) {
    return statesToKeep.reduce((obj, meta) => {
      const path = getPath(meta);
      if (!path) {
        return obj;
      }
      const parts = path.split('.');
      const value = getValue(state, path);
      return parts.reduceRight((acc, part) => part in obj ? {
        [part]: {
          ...obj[part],
          ...acc
        }
      } : {
        [part]: acc
      }, value);
    }, {});
  }
  overwriteStates(state, statesToOverwrite, values) {
    statesToOverwrite.forEach((meta, index) => {
      const path = getPath(meta);
      if (!path) {
        return;
      }
      state = setValue(state, path, values[index]);
    });
    return state;
  }
  resetStates(state, statesToReset) {
    statesToReset.forEach(meta => {
      const path = getPath(meta);
      if (!path) {
        return;
      }
      state = setValue(state, path, typeof meta.defaults === 'undefined' ? {} : meta.defaults);
      if (meta.children) {
        state = this.resetStates(state, meta.children.map(getMetaData));
      }
    });
    return state;
  }
  resetStatesAll(state, statesToKeep) {
    const [metas, values] = statesToKeep.reduce((acc, meta) => {
      const path = getPath(meta);
      if (!path) {
        return acc;
      }
      acc[0].push(meta);
      acc[1].push(getValue(state, path));
      return acc;
    }, [[], []]);
    return this.overwriteStates(this.resetService.initialState, metas, values);
  }
  handle(state, action, next) {
    const type = getActionTypeFromInstance(action);
    switch (type) {
      case StateClear.type:
        state = this.clearStates(state, action.statesToKeep);
        break;
      case StateReset.type:
        state = this.resetStates(state, action.statesToReset);
        break;
      case StateResetAll.type:
        state = this.resetStatesAll(state, action.statesToKeep);
        break;
      case StateOverwrite.type:
        const {
          statesToOverwrite,
          values
        } = action;
        state = this.overwriteStates(state, statesToOverwrite, values);
        break;
      default:
        break;
    }
    return next(state, action);
  }
  /** @nocollapse */
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "18.0.4",
    ngImport: i0,
    type: NgxsResetPlugin,
    deps: [{
      token: ResetService
    }],
    target: i0.ɵɵFactoryTarget.Injectable
  });
  /** @nocollapse */
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "18.0.4",
    ngImport: i0,
    type: NgxsResetPlugin
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.0.4",
  ngImport: i0,
  type: NgxsResetPlugin,
  decorators: [{
    type: Injectable
  }],
  ctorParameters: () => [{
    type: ResetService
  }]
});
function getPath(meta) {
  return meta.path;
}
function withNgxsResetPlugin() {
  return makeEnvironmentProviders([withNgxsPlugin(NgxsResetPlugin), ResetService, ResetHandler, {
    provide: ENVIRONMENT_INITIALIZER,
    useFactory: noop,
    deps: [ResetHandler],
    multi: true
  }]);
}

/**
 * Generated bundle index. Do not edit.
 */

export { NgxsResetPlugin, StateClear, StateOverwrite, StateReset, StateResetAll, getMetaData, withNgxsResetPlugin };

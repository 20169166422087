import { IbanCheckService } from '@abcfinlab/api/global';
import { FormValidator, Validators as CoreValidators } from '@abcfinlab/core';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BankAccountDTO } from '../../../../../../../apps/l7/src/app/models/BankAccountDTO.interface';
import { ScrollToErrorDirective } from '../../../../../../ui/src/Controls/Directives/ScrollToErrorDirective/ScrollToErrorDirective';

@UntilDestroy()
@Component({
    selector: 'l7-bank-account-checker',
    templateUrl: './bank-account-checker.component.html',
    styleUrls: ['./bank-account-checker.component.scss'],
    standalone: false,
})
export class BankAccountCheckerComponent implements AfterViewInit {

    @Output() loaded = new EventEmitter<boolean>();
    @Output() changed = new EventEmitter<BankAccountDTO>();

    @Output() ibanValidity = new EventEmitter<boolean>();

    @Output() statusChanges: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() accountOwner: string;

    @Input() showBankOwner: boolean;

    @Input() showOnlyOwnerAndIban: boolean;

    @Input() isOwnerEditable: boolean;

    @Input() ngxsFormName: string;

    @ViewChildren(ScrollToErrorDirective) scrollToErrorDirectives: QueryList<ScrollToErrorDirective>;

    public internalForm: UntypedFormGroup;
    public loading: boolean = false;

    constructor(
        private readonly _fb: UntypedFormBuilder,
        private readonly _ibanCheckService: IbanCheckService,
        private readonly _cdr: ChangeDetectorRef,
        private readonly _formValidator: FormValidator,
    ) {
        this.internalForm = this._createForm();
    }

    ngAfterViewInit(): void {
        if (this.accountOwner) {
            this.internalForm.controls.bank_account_name.patchValue(this.accountOwner);
        }
        this.internalForm.get('iban').statusChanges.pipe(
            distinctUntilChanged(),
            debounceTime(200),
            untilDestroyed(this),
        ).subscribe((status) => {
            if (status === 'VALID') {
                this._getBankInformation();
            } else {
                this._handleInvalidIBAN();
            }
        });

        this.internalForm.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe((status: string) => this.statusChanges.emit(status === 'VALID'));

        this.internalForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((_) => {
                this.changed.emit(this.internalForm.getRawValue());
                this._cdr.detectChanges();
            });

        if (this.showOnlyOwnerAndIban) {
            this.internalForm.controls.bank_account_name.enable();
            this.internalForm.controls.bank_account_name.removeValidators([Validators.required]);
            this.internalForm.controls.iban.removeValidators([Validators.required]);
            this.internalForm.updateValueAndValidity();
        }
        if (this.isOwnerEditable) {
            this.internalForm.controls.bank_account_name.enable();
        }

        this._getBankInformation();
    }

    get bank_owner_display() {
        return this.showBankOwner ? 'block' : 'none';
    }

    /**
*
* @private
*/
    private _createForm(): UntypedFormGroup {
        return this._fb.group({
            bank_account_name: [{ value: null, disabled: true }, [Validators.required]],
            iban: [
                { value: null, disabled: false },
                [Validators.required],
                [CoreValidators.validateIban(this._ibanCheckService)],
            ],
            bank_name: [{ value: null, disabled: true }, [Validators.required]],
            bic: [{ value: null, disabled: true }, [Validators.required]],
        });
    }

    /**
* Call the API to receive corresponding values for the given IBAN.
* @param iban
* @private
*/
    private _getBankInformation() {
        if (!this.internalForm.getRawValue().iban) {
            return;
        }
        const iban = this.internalForm.getRawValue().iban.toUpperCase();
        this.loading = true;
        this.loaded.emit(false);
        if (this.internalForm.getRawValue().bank_account_name) {
            this.accountOwner = this.internalForm.getRawValue().bank_account_name;
        }
        this._ibanCheckService.getBankAccountbyIban({ iban }).subscribe((response) => {
            this.internalForm.controls.bank_account_name.patchValue(this.accountOwner);
            this.internalForm.controls.bic.patchValue(response.bic);
            this.internalForm.controls.bank_name.patchValue(response.bank_name);
            this.changed.emit(this.internalForm.getRawValue());
            this.loading = false;
            this.loaded.emit(true);
            this.ibanValidity.emit(true);
            this.internalForm.markAllAsTouched();
            this.loaded.emit(true);
        });
    }

    /**
* Reset the BIC/Name field on invalid IBAN input.
* @private
*/
    private _handleInvalidIBAN(): void {
        if (this.internalForm.getRawValue().bank_account_name) {
            this.accountOwner = this.internalForm.getRawValue().bank_account_name;
        }
        this.internalForm.controls.bank_account_name.patchValue(this.accountOwner);
        this.internalForm.controls.bic.patchValue(null);
        this.internalForm.controls.bank_name.patchValue(null);
        this.ibanValidity.emit(false);
    }

    public transformToUpperCase(evt) {
        const value: string = evt.target.value.toUpperCase();
        this.internalForm.get('iban').patchValue(value);

        if (value === '') {
            this.internalForm.get('iban').patchValue(null);
            this.internalForm.get('bank_name').patchValue(null);
            this.internalForm.get('bic').patchValue(null);
        }
    }

    public checkForErrors(): void {
        this._formValidator.validate(this.internalForm);
        if (!this.internalForm.valid) {
            this.scrollToErrorDirectives.first.onSubmit();
        }
    }

}

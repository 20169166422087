<mat-card fxFlex
          fxLayout="column">
    <l7-text matCardTitle
             class="padding"
             [text]="'news.widget.news.title' | translate"
             [truncate]="true"></l7-text>
    <mat-card-content fxFlex
                      fxLayout="column"
                      fxLayoutGap="16px"
                      cdkScrollable
                      style="margin-inline: -16px; overflow: overlay;">
        <ng-container *ngIf="(this.presenter.isBusy | async) === false && (this.presenter.isEmpty | async) === false">
            <mat-nav-list>
                <ng-container *ngFor="let item of this.presenter.news | async">
                    <mat-list-item (click)="this.presenter.onHandle(item)">
                        <div fxLayout="row"
                             fxLayoutAlign="start center"
                             fxLayoutGap="12px">
                            <span *ngIf="item.unread"
                                  class="dot"></span>
                            <l7-text-block [text]="item.publishedAt | date: 'longDate'"></l7-text-block>
                        </div>
                        <l7-text-block [wrap]="true"
                                       [text]="item.title"></l7-text-block>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </ng-container>
            </mat-nav-list>
        </ng-container>

        <ng-container *ngIf="this.presenter.isBusy | async">
            <div fxFlex
                 fxLayoutAlign="center center"
                 motion
                 [motionPreset]="'fadeSlideIn'">
                <mat-progress-spinner [diameter]="48"
                                      [mode]="'indeterminate'"></mat-progress-spinner>
            </div>
        </ng-container>

        <ng-container *ngIf="this.presenter.isEmpty | async">
            <div fxFlex
                 fxLayoutAlign="center center"
                 motion
                 [motionPreset]="'fadeSlideIn'">
                <l7-text-block [text]="'news.widget.news.empty' | translate"></l7-text-block>
            </div>
        </ng-container>
    </mat-card-content>
</mat-card>

/* libs/legacy/gwg/src/lib/success/success/success.component.scss */
.align-center > div {
  display: flex;
  justify-content: center;
}
.body-success {
  text-align: center;
  padding: 0 8%;
}
h4,
h1 {
  margin: 10px 0;
  width: auto;
}
/*# sourceMappingURL=success.component.css.map */

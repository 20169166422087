import { ContactService, IContactDto, IIdTypeDto, INavContactNoDto } from '@abcfinlab/api/contact';
import { IContactDto as IContactDtoGlobal, QuoteUpdateV2Service } from '@abcfinlab/api/global';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ContactDTO } from '../../models/ContactDTO.interface';

@Injectable({
    providedIn: 'root',
})
export class LesseeRepositoryService {

    constructor(
        private readonly _contactService: ContactService,
        private readonly _quoteUpdateService: QuoteUpdateV2Service,
    ) {
    }

    /**
    * Receive the selected lessee from contact api by uuid
    */
    public getLessee(lesseeUUID: string): Observable<ContactDTO | IContactDto> {
        if (typeof lesseeUUID === 'undefined' || lesseeUUID === null || lesseeUUID === '') {
            return throwError(new Error('Missing UUID on lessee request!'));
        }
        return this._contactService.getById({ id: lesseeUUID, idType: IIdTypeDto.Id }).pipe(
            // shareReplay(),
            catchError(err => throwError(new Error('Unable to get lessee by UUID!'))),
        );
    }

    public getCheckContactState(crefoId: string, kind: 'vendor' | 'lessee'): Observable<INavContactNoDto> {
        return this._contactService.checkContactNavStateByType({ id: crefoId, contactType: kind, idType: IIdTypeDto.Crefo });
    }

    public checkIfNewContactNeeded(crefoId: string): Observable<ContactDTO | IContactDto> {
        return this._contactService.checkIfNewContactNeeded({ id: crefoId, idType: IIdTypeDto.Crefo });
    }

    public checkIfNewContactNeededForContractStart(contactId: string): Observable<IContactDto> {
        return this._contactService.checkIfNewContactNeededForContractStart({ id: contactId, idType: IIdTypeDto.Id });
    }

    public updateLesseeForQuote(lessee: IContactDtoGlobal, UUID: string) {
        return this._quoteUpdateService.updateLesseeForLeasingQuote({ leasingQuoteId: UUID, body: lessee });
    }

}

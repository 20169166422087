<header id="application-header">
    <div class="header-col left">
        <ng-container *ngIf="authService.hasSession | async">
            <button *ngIf="canGoBack"
                    mat-icon-button
                    (click)="this.onBack()">
                <mat-icon [fontSet]="'l7'"
                          [fontIcon]="'Arrow_Left'"></mat-icon>
            </button>
            <button *ngIf="(isMobile | async) && (hasRealmLogins | async)"
                    mat-icon-button
                    (click)="showNews()">
                <mat-icon [fontSet]="'l7'"
                          [fontIcon]="'Bell'"
                          [matBadge]="newsCount | async"
                          [matBadgeHidden]="(newsCount | async) === 0"
                          [matBadgeColor]="'warn'"
                          [matBadgeSize]="'small'">
                </mat-icon>
            </button>
        </ng-container>
    </div>
    <div class="header-col center">
        <a fxLayout="column"
           fxLayoutAlign="center center"
           [routerLink]="['']">
            <img src="assets/images/abc_logo_color.svg"
                 alt="abcfinance Logo">
            <small *ngIf="this.stage !== 'prod'">{{ stage }}</small>
        </a>
    </div>
    <div class="header-col right">
        <ng-container *ngIf="authService.hasSession | async">
            <l7-camera-indicator></l7-camera-indicator>
            <button *ngIf="(isMobile | async) === false && (hasRealmLogins | async)"
                    mat-icon-button
                    (click)="showNews()">
                <mat-icon [fontSet]="'l7'"
                          [fontIcon]="'Bell'"
                          [matBadge]="newsCount | async"
                          [matBadgeHidden]="(newsCount | async) === 0"
                          [matBadgeColor]="'warn'"
                          [matBadgeSize]="'small'">
                </mat-icon>
            </button>
            <l7-profile-menu></l7-profile-menu>
        </ng-container>
    </div>
</header>
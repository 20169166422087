/* libs/legacy/gwg/src/lib/shared/identification-pictures/identification-pictures.component.scss */
div {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
div img {
  max-height: 22vh;
}
div .image-preview {
  position: relative;
  width: 240px;
  height: 300px;
}
/*# sourceMappingURL=identification-pictures.component.css.map */

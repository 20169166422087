<footer class="l7-footer"
        *ngIf="show_footer">
  <div class="l7-footer__left">
    <img class="l7-footer__logo"
         src="assets/images/abc_logo_white.svg"
         alt="">
  </div>
  <div class="l7-footer__right">
    <a [routerLink]="['/imprint']"
       target="_blank"
       rel="noopener noreferrer"
       routerLinkActive="active-link">{{"global.impressum" | translate}}</a>
    <a [routerLink]="['/privacy-notice']"
       target="_blank"
       rel="noopener noreferrer"
       routerLinkActive="active-link">{{"global.data.protection" | translate}}</a>
  </div>
</footer>
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'l7-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    standalone: false,
})
export class NotFoundComponent {

    constructor(
        private readonly _router: Router,
    ) {
    }

    public onBack(): void {
        this._router.navigate(['']).then();
    }

}

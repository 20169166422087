/* apps/l7/src/app/shared/components/headline/headline.component.scss */
:host {
  display: block;
  margin-bottom: 40px;
}
:host .headline-container {
  overflow-wrap: break-word;
}
:host .headline-container h1,
:host .headline-container h2,
:host .headline-container h3,
:host .headline-container h4,
:host .headline-container h5,
:host .headline-container h6 {
  width: initial;
}
:host .headline-container .headline {
  text-transform: uppercase;
  font-weight: 600;
  padding: 20px 0;
  word-break: break-word;
  font-size: 1.6em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
@media screen and (min-width: 1024px) {
  :host .headline-container .headline {
    font-size: 2em;
  }
}
:host .headline-container .headline__prefix {
  text-transform: uppercase;
  color: #9e9e9e;
  font-weight: 400;
}
:host .headline-container .headline__additional {
  font-size: large;
  font-weight: bold;
}
:host .headline-container .headline__subheadline {
  font-size: medium;
  display: block;
  color: #5e6f79;
  font-weight: 200;
}
/*# sourceMappingURL=headline.component.css.map */

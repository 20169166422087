// #region Imports

import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TagCategories } from '../../../../apps/l7/src/app/models/enums/TagCategories.enum';

// #endregion

/**
 * @public
 */
@Pipe({
    name: 'tagcolor',
    standalone: false,
})
export class TagColorPipe implements PipeTransform {

    // #region Methods

    public transform(value?: string): string {
        switch (value) {
            case TagCategories.NOTICE:
                return 'warning';
            case TagCategories.NEW:
                return 'primary';
            case TagCategories.IMPROVEMENT:
                return 'info';
        }
    }

    // #endregion

}

/**
 * @public
 */
@NgModule({
    declarations: [TagColorPipe],
    exports: [TagColorPipe],
})
export class TagColorPipeModule {
}

/* libs/news/src/Views/WhatsNewView.scss */
:host h1,
:host h2,
:host h3,
:host h4,
:host h5,
:host h6 {
  margin: initial;
}
:host .item {
  text-align: initial;
}
:host .item .published-at {
  font-size: 10px;
  color: #889ca5;
}
:host .item .header {
  font-weight: bold;
}
:host .item .content {
  color: #5e6f79;
}
:host .item .media {
  max-width: 80%;
  height: auto;
  width: auto;
}
:host .item .dot {
  background-color: #a72329;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: block;
  margin: 24px 6px 6px 6px;
}
/*# sourceMappingURL=WhatsNewView.css.map */

/* libs/legacy/gwg/src/lib/shared/sales-lead-information/sales-lead-information.component.scss */
:host .entry-contact {
  display: flex;
  flex-direction: column;
}
:host .entry-contact label,
:host .entry-contact span {
  display: block;
  width: 100%;
}
:host .entry-contact a {
  margin: 0;
}
.container-sales-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.container-sales-info .contact-icon {
  display: none;
}
.container-sales-info .contact-information {
  width: 100%;
  padding: 16px 24px;
}
.container-sales-info .contact-information label,
.container-sales-info .contact-information span {
  line-height: 1.5em;
}
.container-sales-info .contact-information label {
  display: flex;
  width: 100%;
  font-weight: bold;
  color: #83929b;
}
.container-sales-info .contact-information span {
  display: flex;
  width: 100%;
  color: #8c9aa2;
}
@media (min-width: 1280px) {
  .container-sales-info {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0;
    width: 50%;
    max-width: 512px;
  }
  .container-sales-info .contact-icon {
    display: block;
    padding: 16px 0 24px;
  }
  .container-sales-info .contact-information label,
  .container-sales-info .contact-information span,
  .container-sales-info .contact-information a {
    float: left;
  }
}
/*# sourceMappingURL=sales-lead-information.component.css.map */

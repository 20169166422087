/* libs/news/src/Views/Widgets/WhatsNewWidgetView.scss */
:host {
  width: 100%;
  height: 100%;
  display: flex;
}
:host mat-card {
  padding-bottom: unset;
}
:host mat-card mat-nav-list {
  padding: 0;
  margin: 0;
}
:host .dot {
  background-color: #a72329;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: block;
}
/*# sourceMappingURL=WhatsNewWidgetView.css.map */

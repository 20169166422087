// #region Imports

import { DirectusService, IFaqItemDto, INewsItemDto } from '@abcfinlab/api/global';
import { once, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService } from '@abcfinlab/ui';
import { Inject, Injectable } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { IWhatsNewViewDialogData } from '../Models/Interfaces/IWhatsNewViewDialogData';
import { WhatsNewView } from './WhatsNewView';

// #endregion

/**
 * The presenter of the @{link WhatsNewView} view.
 *
 * @internal
 */
@Injectable()
export class WhatsNewViewPresenter {

    // #region Fields

    private readonly _dialogRef: MatDialogRef<WhatsNewView>;
    private readonly _newsService: DirectusService;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _items: BehaviorSubject<Array<INewsItemDto | IFaqItemDto>>;
    private readonly _dialogDataSubject: BehaviorSubject<IWhatsNewViewDialogData>;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `WhatsNewViewPresenter` class.
     *
     * @public
     */
    public constructor(dialogRef: MatDialogRef<WhatsNewView>, @Inject(MAT_DIALOG_DATA) dialogData: IWhatsNewViewDialogData, newsService: DirectusService,
        busyBoxService: BusyBoxService, translationFacade: TranslationFacade) {
        this._dialogRef = dialogRef;
        this._newsService = newsService;
        this._busyBoxService = busyBoxService;
        this._translationFacade = translationFacade;
        this._items = new BehaviorSubject([]);
        this._dialogDataSubject = new BehaviorSubject(dialogData);
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `kind` property.
     *
     * @public
     * @readonly
     */
    public get data(): Observable<IWhatsNewViewDialogData> {
        return this._dialogDataSubject.asObservable();
    }

    /**
     * Returns the `items` property.
     *
     * @public
     * @readonly
     */
    public get items(): Observable<Array<INewsItemDto | IFaqItemDto>> {
        return this._items.asObservable();
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        if (this._dialogDataSubject.value.kind === 'news') {
            once(this._busyBoxService.show('', this._translationFacade.translate('global.busy'), this._newsService.getItemsV()), (x) => {
                this._items.next(x);
                once(this._newsService.visit());
                setTimeout(() => {
                    if (this._dialogDataSubject.value.id) {
                        const item = document.getElementById(`news_item_${this._dialogDataSubject.value.id}`);
                        item.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                    }
                }, 100);
            });
        } else if (this._dialogDataSubject.value.kind === 'faq') {
            once(this._busyBoxService.show('', this._translationFacade.translate('global.busy'), this._newsService.getFaqs()), (x) => {
                this._items.next(x);
                once(this._newsService.visit());
            });
        }
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
    }

    /**
     * @internal
     */
    public onClose(canceled: boolean): void {
        this._dialogRef.close({
            canceled: canceled,
        });
    }

    // #endregion

}

<div fxLayout="column"
     fxLayoutGap="16px">
  <h2>{{"gwg.contract_data" | translate}}</h2>
  <h1>{{"gwg.contract_close" | translate}}</h1>
  <p>{{"gwg.choose_one_option" | translate}}</p>
  <div class="container-buttons"
       fxLayout="row"
       fxLayout.lt-md="column"
       fxFlexAlign="center center"
       fxLayoutGap="16px">
    <mat-card appearance="outlined" class="mat-card-outlined">
      <mat-card-header fxLayoutAlign="center center">
          <div matCardTitle>
              <mat-icon [fontSet]="'l7'"
                        [fontIcon]="'File_Agreement'"></mat-icon>
          </div>
      </mat-card-header>
      <mat-card-content fxLayout="column"
                        fxLayoutGap="16px">
        <l7-text-block [truncate]="true"
                       [text]="'Vertrag jetzt unterschreiben' | translate"
                       [alignment]="'center'"></l7-text-block>
        <l7-text-block [readonly]="true"
                       [wrap]="true"
                       [text]="'Der Vertrag kann nun direkt vor Ort von Ihrem Kunden abgeschlossen werden.' | translate"
                       [alignment]="'center'"></l7-text-block>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions>
        <button fxFlex
                mat-button
                [color]="'accent'"
                [id]="'btn_local'"
                (click)="goLocal()">{{ 'Vertrag jetzt unterzeichnen' | translate }}</button>
      </mat-card-actions>
    </mat-card>
    <mat-card appearance="outlined" class="mat-card-outlined">
      <mat-card-header fxLayoutAlign="center center">
          <div matCardTitle>
              <mat-icon [fontSet]="'l7'"
                        [fontIcon]="'Globe'"></mat-icon>
          </div>
      </mat-card-header>
      <mat-card-content fxLayout="column"
                        fxLayoutGap="16px"
                        fxLayoutAlign="start center">
        <l7-text-block [truncate]="true"
                       [text]="'Später unterschreiben' | translate"
                       [alignment]="'center'"></l7-text-block>
        <l7-text-block [readonly]="true"
                       [wrap]="true"
                       [text]="'Der Vertragsabschluss kann jederzeit von Ihrem Kunden via Link bestätigt werden.' | translate"
                       [alignment]="'center'"></l7-text-block>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions>
        <button fxFlex
                mat-button
                [color]="'accent'"
                [id]="'btn_remote'"
                (click)="goRemote()">{{ 'Per E-Mail senden' | translate }}</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

/* libs/legacy/gwg/src/lib/shared/contract-document-creation-modal/contract-document-creation.modal.scss */
:host .align-center {
  display: flex;
  justify-content: center;
  text-align: center;
}
:host p {
  line-height: 1.8em;
}
:host h2 {
  text-align: center;
}
/*# sourceMappingURL=contract-document-creation.modal.css.map */

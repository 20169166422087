<h1 mat-dialog-title>{{"dialogs.calculation_delete_confirmation_header" | translate}}</h1>
<mat-dialog-content>
  <p>{{confirmationMsg}}</p>
</mat-dialog-content>
<mat-dialog-actions>

  <button mat-button
          id="button_close-delete-quote-modal"
          [matDialogClose]>{{"global.no" | translate}}</button>
    <button mat-flat-button
            [color]="'accent'"
            id="button_delete-quote"
            (click)="onConfirmDelete()">{{"global.yes" | translate}}</button>
</mat-dialog-actions>

/* libs/legacy/gwg/src/lib/shared/gwg-dependency-bar/gwg-dependency-bar.component.scss */
:host {
  position: absolute;
  transform: translateY(-24px);
  right: 0;
  top: 0;
}
:host .gwg-dependency-bar {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
}
:host .gwg-dependency-bar .icons-container {
  display: flex;
  flex-direction: row;
}
:host .gwg-dependency-bar .icon-container {
  margin-left: 8px;
  padding: 8px;
  background-color: white;
  border-radius: 50%;
}
:host .gwg-dependency-bar .icon-container.APPROVED_WITH_CONDITIONS,
:host .gwg-dependency-bar .icon-container.APPROVED_WITH_CONDITIONS_FULFILLED {
  border: none;
}
:host .gwg-dependency-bar .icon-container .btn-with-conditions {
  background-color: transparent;
  border: none;
}
:host .gwg-dependency-bar .icon-container .btn-with-conditions:focus {
  outline: none;
}
:host .gwg-dependency-bar .icon-container .btn-with-conditions .mat-badge {
  padding-right: 10px !important;
  padding-top: 10px !important;
}
:host .gwg-dependency-bar .icon-container .btn-with-conditions .mat-badge-content {
  background: #ffb500;
  color: #b81931;
  width: 18px;
  height: 18px;
  top: -15px;
  right: -12px !important;
  line-height: 17px;
  font-size: 12px;
  text-align: center;
}
:host .gwg-dependency-bar .icon-container mat-icon {
  transform: scale(2);
}
:host .gwg-dependency-bar .icon-container mat-icon.POSITIVE,
:host .gwg-dependency-bar .icon-container mat-icon.APPROVED_WITH_CONDITIONS,
:host .gwg-dependency-bar .icon-container mat-icon.APPROVED_WITH_CONDITIONS_FULFILLED {
  color: #55ed9c;
}
:host .gwg-dependency-bar .icon-container mat-icon.NEGATIVE {
  color: #bf4128;
}
:host .gwg-dependency-bar .icon-container mat-icon.EXPIRED {
  color: #ffce0a;
}
:host .gwg-dependency-bar .icon-container mat-icon.OPEN {
  color: #000000;
}
:host .gwg-dependency-bar .icon-container mat-icon.PENDING {
  color: #6495ed;
}
:host .gwg-dependency-bar .icon-container mat-icon.DISCARDED {
  color: #eeeeee;
}
/*# sourceMappingURL=gwg-dependency-bar.component.css.map */

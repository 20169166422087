import { IInhouseQuoteDto } from '@abcfinlab/api/global';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { StateReset } from 'ngxs-reset-plugin';
import { ResetRemoteState, SetQuoteSummary, SetSalesInformation, SetSigningLinkUuid } from '../actions/Remote.actions';
import { RemoteContactDTO } from '../models/RemoteContactDTO.interface';

export class RemoteStateModel {

    signingLinkUuid: string;
    quote: IInhouseQuoteDto;
    salesInformation: RemoteContactDTO;
    acknowledgement: any;

}

@State<RemoteStateModel>({
    name: 'remote',
    defaults: {
        signingLinkUuid: null,
        quote: null,
        salesInformation: null,
        acknowledgement: {
            objectForm: {
                model: {
                    name: null,
                    serial_number: null,
                    condition: null,
                    purchase_price: null,
                    year_of_construction: null,
                },
                dirty: false,
                status: 'INVALID',
                errors: {},
            },
            signatureForm: {
                model: {
                    signed_by_first_name: null,
                    signed_by_last_name: null,
                    postal_code: null,
                    signed_place: null,
                    acknowledgement_date: null,
                    checkPreRentValue: null,
                    checkTransferDate: null,
                    checkEssentialElement: null,
                },
                dirty: false,
                status: 'INVALID',
                errors: {},
            },
        },
    },
})

@Injectable()
export class RemoteState {

    constructor(private readonly _store: Store) {
    }

    @Selector()
    static getSigningLinkUui(state: RemoteStateModel) {
        return state.signingLinkUuid;
    }

    @Selector()
    static getQuoteSummary(state: RemoteStateModel) {
        return state.quote;
    }

    @Selector()
    static getSalesInformation(state: RemoteStateModel) {
        return state.salesInformation;
    }

    @Selector()
    static getAcknowledgementObjectForm(state: RemoteStateModel) {
        return state.acknowledgement.objectForm.model;
    }

    @Selector()
    static getAcknowledgementSignatureForm(state: RemoteStateModel) {
        return state.acknowledgement.signatureForm.model;
    }

    /**
   *
   */
    @Action(ResetRemoteState)
    resetCalculationState(): void {
        this._store.dispatch(new StateReset(RemoteState));
    }

    @Action(SetSigningLinkUuid)
    setSigningLinkUuid({ patchState }: StateContext<RemoteStateModel>, { payload }: SetSigningLinkUuid) {
        patchState({
            signingLinkUuid: payload,
        });
    }

    @Action(SetQuoteSummary)
    setQuoteSummary({ patchState }: StateContext<RemoteStateModel>, { payload }: SetQuoteSummary) {
        patchState({
            quote: payload,
        });
    }

    @Action(SetSalesInformation)
    setSalesInformation({ patchState }: StateContext<RemoteStateModel>, { payload }: SetSalesInformation) {
        patchState({
            salesInformation: payload,
        });
    }

}

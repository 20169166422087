import { TranslationModule } from '@abcfinlab/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '../../../../../../apps/l7/src/app/shared/shared.module';
import { SuccessComponent } from './success/success.component';
import { SuccessContainerComponent } from './success-container/success-container.component';
import { SuccessRemoteContainerComponent } from './success-remote-container/success-remote-container.component';
import { SuccessRoutingModule } from './success-routing.module';

@NgModule({
    declarations: [
        SuccessComponent,
        SuccessContainerComponent,
        SuccessRemoteContainerComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        SuccessRoutingModule,
        MatButtonModule,
        TranslationModule,
    ],
    exports: [
        SuccessComponent,
        SuccessContainerComponent,
        SuccessRemoteContainerComponent,
    ],
})
export class SuccessModule { }

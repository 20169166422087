import { ContactService } from '@abcfinlab/api/contact';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ContractTypeId } from '../../../../../../apps/l7/src/app/models/enums/ContractTypeId.enum';
import { GWGProcessDataDTO } from '../../../../../../apps/l7/src/app/models/GWGProcessDataDTO.interface';

@Injectable({
    providedIn: 'root',
})
export class LeasingQuoteIdService {

    private _leasingQuoteId: string | null = null;
    private _lesseeId: string | null = null;
    private _contractType: ContractTypeId | null = null;
    private readonly _isIdentificationRequired: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    private readonly _contactService: ContactService;

    constructor(contactService: ContactService) {
        this._contactService = contactService;
    }

    /**
    * Sets up the quote UUID and the contract type for further GWG requests
    * @param gwgProcessData - The necessary data to perform the GWG process
    */
    public setLeasingQuoteDataOnce(gwgProcessData: GWGProcessDataDTO): void {
        this._leasingQuoteId = gwgProcessData.quote_id;
        this._lesseeId = gwgProcessData.lesseeId;
        this._contractType = gwgProcessData.contract_type;
        this._contactService.identificationRequired({ lesseeId: gwgProcessData.lesseeId })
            .subscribe(x => this._isIdentificationRequired.next(x.identificationRequired));
        if (this._leasingQuoteId && this._contractType) {
            sessionStorage.clear();
        } else {
            throw new Error('Unable to set necessary GWG Data!');
        }
    }

    /**
    * Return the contract type of the selected quote
    * Necessary to distinct between *MIETKAUF* and normal *LEASING* routes in requests e.g.
    */
    public getContractType(): ContractTypeId {
        return this._contractType;
    }

    /**
    * Return the UUID of the selected quote
    */
    public getLeasingQuoteId(): string {
        return this._leasingQuoteId;
    }

    /**
     * Return the UUID of the lessee for the selected quote
     */
    public getLesseeId(): string {
        return this._lesseeId;
    }

    public get isIdentificationRequired(): Observable<boolean> {
        return this._isIdentificationRequired.asObservable();
    }

}

<div class="align-center">
  <div>
    <img src="assets/images/gwg-images/success.png"
         alt="">
  </div>
  <div>
    <h1>{{"global.done" | translate}}</h1>
  </div>
  <div class="body-success">
    <h4 class="text-font"
        [innerHtml]="bodyText | translate"></h4>
    <br />
  </div>
  <div>
    <h4 class="text-font"> {{"gwg.success.working_together" | translate}} </h4>
  </div>
  <br />
  <div *ngIf="id === possibleIds.LOCAL">
    <button mat-flat-button
            [color]="'accent'"
            id="button-back-to-start"
            (click)="goToHome()">
      {{"global.start.page" | translate}}
    </button>
  </div>
</div>
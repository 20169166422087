<form [formGroup]="signerForm"
      fxLayout="column">
    <mat-form-field appearance="outline">
        <mat-label>{{ 'Vorname (Und ggf. Zuname/n) des Unterzeichners' }}</mat-label>
        <input id="input_lessee-first-name"
               matInput
               type="text"
               required
               formControlName="first_name">
        <mat-error>Das Feld ist erforderlich.</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>{{ 'Nachname/n des Unterzeichners' }}</mat-label>
        <input id="input_lessee-last-name"
               matInput
               type="text"
               required
               formControlName="last_name">
        <mat-error>Das Feld ist erforderlich.</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>{{ 'E-Mail Adresse des Unterzeichners' }}</mat-label>
        <input id="input_lessee-email"
               matInput
               type="email"
               required
               formControlName="mail_address">
        <mat-error>Die angegebene E-Mail Adresse ist ungültig.</mat-error>
    </mat-form-field>
    <ng-content></ng-content>
</form>
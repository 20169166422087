<div>
     <h3 class="text-break">
          Bitte laden Sie die <span class="text-bold">{{text.toUpperCase()}}</span> des Personalausweises des Unterzeichners hoch.
          <!-- @TODO: GWGPATCH 2 -->
          Folgende Dateiformate sind zulässig: <span class="text-bold">PDF, PNG, JP(E)G</span>. Die zulässige Dateigröße beträgt <span class="text-bold">{{maximum_file_size_text}}</span>.
     </h3>

     <div class="uploadfilecontainer"
          (click)="fileInput.click()"
          l7LibDragDrop
          (fileDropped)="uploadFile($event)"
          *ngIf="!imageDataBase64">
          <img src="assets/images/gwg-images/dragndrop.svg"
               alt=""
               class="dragndrop">
          <input hidden
                 type="file"
                 #fileInput
                 (change)="uploadFile($event.target.files)"
                 id="uploaded-file">
          <p class="error-message align-center"
             *ngIf="errorMessage">{{errorMessage}}</p>
          <p class="label-upload">Date hierher ziehen oder</p>
          <a [routerLink]="[]"
             class="link-upload">Datei auswählen</a>
     </div>

     <div class="container-preview align-center"
          [@openClose]="isExpanded ? true : false"
          *ngIf="imageDataBase64">
          <div class="container-icon"
               (click)="toggleExpand()"
               [@openCloseBtn]="isExpanded ? true : false">
               <mat-icon [fontIcon]="iconExpand"
                         [fontSet]="'l7'"></mat-icon>
          </div>

          <pdf-viewer [original-size]="false"
                      class="image-preview"
                      [src]="pdfOptions"
                      [render-text]="false"
                      *ngIf="isPdf">
          </pdf-viewer>

          <img [src]="imageDataBase64"
               alt=""
               class="image-preview"
               *ngIf="isImage">
     </div>

     <hr class="line">

     <div class="align-center"
          *ngIf="imageDataBase64"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="16px">
          <button mat-button
                  [color]="'accent'"
                  id="button_cancel"
                  (click)="deleteAttachment()">{{text | titlecase}} erneut hochladen</button>
          <button mat-flat-button
                  [color]="'accent'"
                  id="button_save_image"
                  (click)="recordSubmited()">
               Bestätigen
          </button>
     </div>

</div>
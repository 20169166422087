import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { combineLatest, Observable } from 'rxjs';
import { PictureType } from '../models/picture-type.enum';

@Injectable({
    providedIn: 'root',
})
export class PicturesService {

    constructor(
        private readonly _storage: StorageMap,
    ) {
    }

    /**
   * Cache the images on client side as in a Data-URL Format
   * @param direction       Front- or Backside of the Document
   * @param data            Image Data as DataURL
   */
    storeIdentificationDocument(direction: PictureType, data: string): Observable<string> {
        const field = `${direction}_image`;
        return this._storage.set(field, data);
    }

    /**
   * Remove a client side cached document from the Storage
   * @param direction       Front- or Backside of the Document
   */
    removeIdentificationDocument(direction: PictureType): Observable<void> {
        const field = `${direction}_image`;
        return this._storage.delete(field);
    }

    /**
   * Get a client side cached document from the Storage
   * @param direction       Front- or Backside of the Document
   */
    getIdentificationDocument(direction: PictureType): Observable<string> {
        const field = `${direction}_image`;
        return this._storage.get(field) as Observable<string>;
    }

    public clearDocumentStorage(): Observable<Array<void>> {
        sessionStorage.clear();
        return combineLatest([
            this.removeIdentificationDocument(PictureType.Backside),
            this.removeIdentificationDocument(PictureType.Frontside),
        ]);
    }

    /**
   * Get the mime type of a stored document
   * @param documentType       The type of the document to look up
   */
    public getDocumentMimeType(document: string): string {
        const base64ContentArray = document.split(',');
        return base64ContentArray[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0];
    }

    /**
   * Get the file extension to a stored document
   * @param documentType       The type of the document to look up
   */
    public getDocumentFileExtension(document: string): string {
        const mimeType = this.getDocumentMimeType(document);
        if (mimeType === 'image/png') {
            return '.png';
        }
        if (mimeType === 'image/jpeg') {
            return '.jpeg';
        }
        if (mimeType === 'application/pdf') {
            return '.pdf';
        }
    }

}

<button mat-icon-button
        [matMenuTriggerFor]="menu"
        (menuOpened)="this.onMenuOpened()">
    <mat-icon [fontSet]="'l7'"
              [fontIcon]="'Menu'"></mat-icon>
</button>
<mat-menu #menu="matMenu">
    <div class="profile-menu">
        <fieldset class="avatars-container">
            <legend>DU</legend>
            <div class="avatars-avatar-container"
                 fxLayout="row"
                 fxLayoutAlign="start center"
                 fxFlex
                 mat-menu-item
                 (click)="onOpenAccount()">
                <span class="avatar primary">{{ userStrippedName | async }}</span>
                <div class="avatar-text-container"
                     fxFlex
                     fxLayout="column"
                     fxLayoutAlign="center start">
                    <p class="avatar-text">{{ (user | async)?.givenName }} {{ (user | async)?.familyName }}</p>
                    <p class="avatar-text sub">{{ (user | async)?.email }}</p>
                </div>
            </div>
        </fieldset>
        <fieldset *ngIf="(user | async)?.leadId"
                  class="avatars-container">
            <legend>LEAD</legend>
            <a class="avatars-avatar-container"
               fxLayout="row"
               fxLayoutAlign="start center"
               fxFlex
               mat-menu-item
               [href]="(user | async)?.leadEmail | mailto">
                <span class="avatar secondary">{{ leadStrippedName | async}}</span>
                <div class="avatar-text-container"
                     fxFlex
                     fxLayout="column"
                     fxLayoutAlign="center start">
                    <p class="avatar-text">{{ (user | async)?.leadName }} {{ (user | async)?.leadFamilyName }}</p>
                    <p class="avatar-text sub">{{ (user | async)?.leadEmail }}</p>
                </div>
            </a>
        </fieldset>
        <fieldset class="roles-container">
            <legend>ROLLEN</legend>
            <div class="roles-role-container"
                 fxFlex
                 fxLayout="row wrap">
                <span class="role"
                      *ngFor="let group of (userGroups | async)">
                    {{ 'roles.' + group | translate }}
                </span>
            </div>
        </fieldset>
        <fieldset class="actions-container">
            <legend>AKTIONEN</legend>
            <div fxLayout="column"
                 fxFlex>
                <a mat-menu-item
                   id="link_open-account-page"
                   (click)="onOpenAccount()">Mein LEASE SEVEN</a>
                <a mat-menu-item
                   id="link_open-imprint"
                   [routerLink]="['/imprint']"
                   target="_blank"
                   rel="noopener noreferrer">Impressum</a>
                <a mat-menu-item
                   id="link_open-data-protection"
                   [routerLink]="['/privacy-notice']"
                   target="_blank"
                   rel="noopener noreferrer">Datenschutz</a>
                <a mat-menu-item
                   (click)="onLogout()"
                   id="link_logout">Abmelden</a>
            </div>
        </fieldset>
    </div>
</mat-menu>

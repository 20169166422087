import { IInhouseQuoteDto } from '@abcfinlab/api/global';
import { RemoteContactDTO } from '../models/RemoteContactDTO.interface';

export class SetSalesInformation {

    static readonly type = '[REMOTE] Set sales information';
    constructor(public payload: RemoteContactDTO) {
    }

}

export class SetQuoteSummary {

    static readonly type = '[REMOTE] Set quote summary';
    constructor(public payload: IInhouseQuoteDto) {
    }

}

export class SetSigningLinkUuid {

    static readonly type = '[REMOTE] Set signing link uuid';
    constructor(public payload: string) {
    }

}

export class ResetRemoteState {

    static readonly type = '[REMOTE] Reset remote state';
    constructor() {}

}

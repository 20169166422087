/* apps/l7/src/app/shared/components/footer/footer.component.scss */
.l7-footer {
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background-color: #19485a;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 18px #ddd;
  text-align: center;
  flex-direction: row;
}
.l7-footer__left {
  display: none;
  width: 50%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (min-width: 600px) {
  .l7-footer__left {
    display: flex;
  }
}
.l7-footer__right {
  min-height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.l7-footer__right a {
  color: #aaa;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1em;
  padding: 0 10px;
}
@media screen and (min-width: 600px) {
  .l7-footer__right a {
    padding: unset;
    margin-right: 20px;
  }
}
.l7-footer__right a:hover {
  color: #fff;
}
@media screen and (min-width: 600px) {
  .l7-footer__right {
    width: 50%;
  }
}
.l7-footer__logo {
  max-height: 20px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.l7-footer__link {
  color: #aaa;
}
/*# sourceMappingURL=footer.component.css.map */

<div class="container-sales-info">
  <div class="contact-icon">
      <mat-icon class="completed"
                [fontIcon]="'Call_Calling'" [fontSet]="'l7'"></mat-icon>
  </div>
  <div class="contact-information">
    <div class="entry-contact">
      <label>{{"gwg.lead.label_lead" | translate}}</label><span>{{salesLead?.first_name}} {{salesLead?.name}}</span>
    </div>
    <div class="entry-contact">
      <span>{{salesLead?.street}} {{salesLead?.house_number}}, {{salesLead?.postcode}} {{salesLead?.city}}</span>
    </div>
    <div class="entry-contact">
      <label>{{"global.label_phone" | translate}}</label>
      <span class="entry-phone">
        <a href="tel:{{salesLead?.phone}}">{{salesLead?.phone}}</a>&nbsp;|&nbsp;<a href="tel:{{salesLead?.mobile}}">{{salesLead?.mobile}}</a>
      </span>
    </div>
    <div class="entry-contact">
      <label>{{"global.label_email" | translate}}</label>
      <span><a href="mailto:{{salesLead?.mail}}">{{salesLead?.mail}}</a></span>
    </div>
  </div>
</div>

<h1>Kontrolle des Personalausweises des Unterzeichners</h1>
<h3>Bitte kontrollieren Sie die Qualität der <span class="text-bold">
    {{text.toUpperCase()}}</span> des Personalausweises und nehmen Sie das Foto bei Bedarf erneut
  auf.
</h3>
<div class="align-center">
  <img [src]="imageAsDataUrl"
       alt="{{text.toUpperCase()}}" />
</div>
<hr>
<div class="align-center">
  <p>Die <span class="text-bold">{{text.toUpperCase()}}</span> des Dokuments ist vollständig und lesbar fotografiert.</p>
</div>
<div class="align-center">
    <l7-spacer [thickness]="'all'" [multiplier]="24" fxLayout="row" fxLayoutGap="8px">
        <button mat-button
                [color]="'accent'"
                id="button_try_again"
                (click)="retry()">
            {{text}} erneut aufnehmen
        </button>
        <button mat-flat-button
                [color]="'accent'"
                id="button_confirm_img"
                (click)="verified()">
            Bestätigen
        </button>
    </l7-spacer>
</div>

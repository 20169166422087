import { IContactDto } from '@abcfinlab/api/contact';
import {
    IContractTypeDto,
    IHirePurchaseQuoteDto,
    ILeasingQuoteDto,
    QuoteService,
    QuoteUpdateService,
} from '@abcfinlab/api/global';
import { AppConfig } from '@abcfinlab/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SigningLinkDTO } from '../../../../../../libs/legacy/gwg/src/lib/models/SigningLinkDTO.interface';
import { NavInfoDTO } from '../../../app/models/NavContactNoDTO.interface';
import { ContactDTO } from '../../models/ContactDTO.interface';
import { ContractTypeId } from '../../models/enums/ContractTypeId.enum';
import { LeasingQuoteDTO } from '../../models/LeasingQuoteDTO.interface';
import { LeasingQuoteOverviewDTO } from '../../models/LeasingQuoteOverviewDTO.interface';
import { VendorDTO } from '../../models/VendorDTO.interface';

export enum ErrorsReadyForSignature {
    CREDIT_RATING = 'CREDIT_RATING',
    VENDOR_MISSING = 'VENDOR_MISSING',
    BANK_ACCOUNT_MISSING = 'BANK_ACCOUNT_MISSING',
    SIGNING_LINK_ACTIVE = 'SIGNING_LINK_ACTIVE',
    QUOTE_STATUS = 'QUOTE_STATUS',
}

export interface ReadyForSignatureDTO {
    ready_for_signature: boolean;
    errors: Array<ErrorsReadyForSignature>;
}

@Injectable({
    providedIn: 'root',
})
export class QuoteRepositoryService {

    protected readonly HOSTURL: string;
    protected readonly CONTEXT: string = '/api/v1/quote';
    protected readonly CONTEXT_V2: string = '/api/v2/quote';
    protected readonly CONTEXT_SIGNING_LINK: string = '/api/v1/signinglink';
    protected readonly CONTEXT_NAV_INFO: string = '/api/v1/navisioninfo';

    constructor(
        private readonly _http: HttpClient,
        private readonly _router: Router,
        private readonly appConfig: AppConfig,
        private readonly _quoteService: QuoteService,
        private readonly _quoteUpdateService: QuoteUpdateService,
    ) {
        this.HOSTURL = appConfig.get('host');
    }

    /**
   * Update a quote by changing payment information/fee values or object name (on quote detail view f.e.).
   * Used on quote detail screen.
   */
    public updateQuote(leasingQuote: LeasingQuoteDTO | IHirePurchaseQuoteDto | ILeasingQuoteDto): Observable<LeasingQuoteDTO | IHirePurchaseQuoteDto | ILeasingQuoteDto> {
        if (typeof leasingQuote === 'undefined' || leasingQuote === null) {
            throw new Error('Cannot update quote without a quote.');
        }

        switch (leasingQuote.quote_calculation.contract_type) {
            case IContractTypeDto.Mietkauf:
            case IContractTypeDto.Mkn:
                return this._quoteUpdateService.updateHirePurchaseQuote({ body: leasingQuote as IHirePurchaseQuoteDto });
            default:
                return this._quoteUpdateService.updateLeasingQuote({ body: leasingQuote as ILeasingQuoteDto });
        }
    }

    public createQuoteV2(payload: ContactDTO): Observable<LeasingQuoteDTO> {
        return this._http.post<LeasingQuoteDTO>(`${this.HOSTURL + this.CONTEXT_V2}/lessee`, payload, { withCredentials: true }).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err instanceof HttpErrorResponse && err.error.error !== 'contact_lefo_not_whitelisted') {
                    void this._router.navigate(['../../quote/create/search-lessee'], {
                        relativeTo: this._router.routerState.root,
                    });
                }
                return throwError(err);
            }),
        );
    }

    /**
   * Save a quote depending on the mode.
   * Create a new quote or updates a existing one.
   */
    public saveQuote(leasingQuote: LeasingQuoteDTO, mode: string): Observable<LeasingQuoteDTO> {
        if (typeof leasingQuote === 'undefined' || !leasingQuote) {
            return throwError(new Error('No quote provided!'));
        }
        const contractType: string = leasingQuote.quote_calculation.contract_type === ContractTypeId.MIETKAUF ? 'hirepurchase' : 'leasing';
        if (mode === 'CREATE') {
            return this._http.post<LeasingQuoteDTO>(`${this.HOSTURL}${this.CONTEXT}/${contractType}`, leasingQuote, { withCredentials: true });
        }
        if (mode === 'UPDATE') {
            return this._http.post<LeasingQuoteDTO>(`${this.HOSTURL}${this.CONTEXT}/${contractType}/version`, leasingQuote, { withCredentials: true });
        }
    }

    /**
   * Get quote version list by lessee uuid
   * @param lesseeUUID    The lessees unique identifier
   */
    public getQuotesByLesseeUUID(lesseeUUID: string): Observable<LeasingQuoteOverviewDTO> {
        if (typeof lesseeUUID === 'undefined' || lesseeUUID === null || lesseeUUID === '') {
            throw new Error('Cannot get quotes for lessee. No lessee id provided.');
        }
        return this._http.get<LeasingQuoteOverviewDTO>(`${this.HOSTURL}${this.CONTEXT}/lessee/${lesseeUUID}`, { withCredentials: true }).pipe(
            catchError(err => of({ quotes: [] })),
        );
    }

    /**
   * Get a single quote by the unique QuoteUUID
   * @param quoteUUID   The quotes unique identifier
   */
    public getQuoteByUUID(quoteUUID: string, contractType: ContractTypeId | IContractTypeDto): Observable<LeasingQuoteDTO> {
        let leasingQuoteSummaryRequest$: any;
        if (typeof quoteUUID === 'undefined' || quoteUUID === null || quoteUUID === '' || !contractType) {
            throw new Error('Cannot get quote without providing the quotes UUID.');
        }
        switch (contractType) {
            // exceptional case for *MIETKAUF* and *MIETKAUF Netto* quotes
            case IContractTypeDto.Mietkauf:
            case IContractTypeDto.Mkn:
                leasingQuoteSummaryRequest$ = this._quoteService.getHirePurchaseQuoteById({ quoteId: quoteUUID });
                break;
            // handling defaults to *VA*, *TA*, *IT-Flex* ... quotes
            default:
                leasingQuoteSummaryRequest$ = this._quoteService.getLeasingQuoteById({ quoteId: quoteUUID });
        }
        return leasingQuoteSummaryRequest$;
    }

    public setVendorForQuoteV2(vendor: VendorDTO): Observable<any> {
        if (typeof vendor === 'undefined' || vendor === null) {
            return throwError('Parameter is missing!');
        }
        return this._http.put(`${this.HOSTURL}${this.CONTEXT_V2}/vendors/${vendor.quote_id}`, vendor, { withCredentials: true });
    }

    /**
   * get a signing link by QuoteUUID
   */
    public fetchSigningLink(quoteUUIID: string): Observable<SigningLinkDTO> {
        if (typeof quoteUUIID === 'undefined' || quoteUUIID === null || quoteUUIID === '') {
            throw new Error('Cannot get link without providing the quotes UUID.');
        }
        return this._http.get<SigningLinkDTO>(`${this.HOSTURL}${this.CONTEXT_SIGNING_LINK}/${quoteUUIID}`, { withCredentials: true });
    }

    /**
   * check if a quote is ready for signature
   */
    public quoteReadyForSignature(quoteUUIID: string): Observable<ReadyForSignatureDTO> {
        if (typeof quoteUUIID === 'undefined' || quoteUUIID === null || quoteUUIID === '') {
            throw new Error('Cannot get state of the quotes UUID.');
        }
        return this._http.get<ReadyForSignatureDTO>(`${this.HOSTURL}${this.CONTEXT}/readyforsignature/${quoteUUIID}`, { withCredentials: true });
    }

    /**
   * get nav info for quote
   */
    public fetchNavInfo(quoteNo: number): Observable<NavInfoDTO> {
        return this._http.get<NavInfoDTO>(`${this.HOSTURL}${this.CONTEXT_NAV_INFO}/${quoteNo}`, { withCredentials: true });
    }

    updateVendorForQuote(vendor: ContactDTO | IContactDto, UUID: string): Observable<HttpResponse<any>> {
        if (typeof vendor === 'undefined' || vendor === null) {
            return throwError('Parameter is missing!');
        }
        return this._http.put<HttpResponse<any>>(`${this.HOSTURL}${this.CONTEXT_V2}/vendors/update/${UUID}`, vendor, { withCredentials: true });
    }

}

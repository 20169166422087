<h2 mat-dialog-title fxLayout="column" fxLayoutAlign="center center" *ngIf="!data.error">
    Wir erstellen gerade <br/> Ihre <br/> {{data.text}}
</h2>
<mat-dialog-content fxLayoutAlign="start center" fxLayout="column" fxLayoutGap="24px">
    <mat-spinner *ngIf="!data.error"></mat-spinner>
    <ng-container *ngIf="!data.error; else showErrorMessage">
        <l7-text-block mat-line [text]="'Bitte haben Sie etwas Geduld!' | translate"></l7-text-block>
    </ng-container>

    <ng-template #showErrorMessage>
        <img src="assets/images/image-failure.svg"
             alt="Server Error">
        <div class="align-center">
            <p [innerHTML]="data.error.message"></p>
        </div>
    </ng-template>

</mat-dialog-content>

<mat-dialog-actions style="justify-content: center;">
    <ng-container *ngIf="data.error">
        <button *ngIf="data.error.button_text"
                mat-flat-button
                [color]="'accent'"
                id="button_left_{{data.error.button_text}}"
                (click)="onConfirm('error')">
            {{data.error.button_text | translate}}
        </button>
    </ng-container>
</mat-dialog-actions>

import { IbanValidatorModule, ScrollToErrorModule, SpacerModule, TextBlockModule } from '@abcfinlab/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatLineModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { WebcamModule } from 'ngx-webcam';
import { SharedModule as ProjectSharedModule } from '../../../../../../apps/l7/src/app/shared/shared.module';
import { TranslationModule } from '../../../../../core/src/Index';
import { PresentationModule } from '../../../../../presentation/src/Modules/PresentationModule';
import {
    BankAccountCheckerComponent,
} from './bank-account-checker/bank-account-checker.component';
import { ContractDocumentCreationModalComponent } from './contract-document-creation-modal/contract-document-creation.modal';
import { FlowDecisionComponent } from './flow-decision/flow-decision.component';
import {
    GwgDependencyBarComponent,
} from './gwg-dependency-bar/gwg-dependency-bar.component';
import { IdentificationPhotoComponent } from './identification-photo/identification-photo.component';
import { IdentificationPicturesComponent } from './identification-pictures/identification-pictures.component';
import { IdentificationUploadFileComponent } from './identification-upload-file/identification-upload-file.component';
import { IdentificationVerificationComponent } from './identification-verification/identification-verification.component';
import { SalesLeadInformationComponent } from './sales-lead-information/sales-lead-information.component';
import { SignerFormComponent } from './signer/signer-form.component';
import { SignerDataProcessingModalComponent } from './signer-data-processing-modal/signer-data-processing-modal.component';

@NgModule({
    declarations: [
        SignerFormComponent,
        IdentificationPhotoComponent,
        IdentificationVerificationComponent,
        IdentificationPicturesComponent,
        ContractDocumentCreationModalComponent,
        SignerDataProcessingModalComponent,
        IdentificationUploadFileComponent,
        FlowDecisionComponent,
        SalesLeadInformationComponent,
        GwgDependencyBarComponent,
        BankAccountCheckerComponent,
    ],
    providers: [provideNgxMask()],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        WebcamModule,
        MatDialogModule,
        PdfViewerModule,
        ProjectSharedModule,
        PresentationModule,
        NgxsFormPluginModule,
        NgxMaskDirective,
        NgxMaskPipe,
        MatInputModule,
        MatFormFieldModule,
        FlexLayoutModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        TextBlockModule,
        SpacerModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        MatLineModule,
        MatTooltipModule,
        MatBadgeModule,
        ScrollToErrorModule,
        FormsModule,
        IbanValidatorModule,
        TranslationModule,
    ],
    exports: [
        SignerFormComponent,
        IdentificationPhotoComponent,
        IdentificationVerificationComponent,
        IdentificationPicturesComponent,
        IdentificationUploadFileComponent,
        FlowDecisionComponent,
        SalesLeadInformationComponent,
        GwgDependencyBarComponent,
        BankAccountCheckerComponent,
    ],
})
export class SharedModule { }
